.confirmDiv{
    background-color: rgba(0,35,73,255);
    height: 100%;
    max-width: 420px;
    margin: auto;
    background-color: #0A2243;
}
.px-5,.px-5:hover{
    width: 85% !important;
    height: 60px;
    background-color: #007BC3;
    color: white;
    font-weight: 600;
    border: none;
    border-radius: 25px !important;
    margin: 0 !important;
    margin: 40px 0 !important;
    font-size: 25px;
    margin-bottom: 10px;
    &.clicked {
        background-color:#007BC3; // Color after button is clicked
    }
}
.footer{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 40px 35px;
}
.heading{
    font-size: 22px;
    font-weight: 500;
    font-family: var(--effra-font-bold) !important;
    margin-bottom: 25px;
}
.subHeading{
    font-size: 22px;
    opacity: 0.8;
    font-weight: 300;
    font-family: var(--effra-font) !important;
}
.copyRight{
    font-size: 10px;
    padding-bottom: 10px;
}