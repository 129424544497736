@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-Regular'),
        url('../fonts/Gilroy-Regular.woff') format('woff2'),
        url('../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy-SemiBold'),
        url('../fonts/Gilroy-SemiBold.woff2') format('woff2'),
        url('../fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Impact';
    src: local('Impact'),
        url('../fonts/Impact.woff2') format('woff2'),
        url('../fonts/Impact.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next';
    src: local('Avenir Next Medium'), local('AvenirNext-Medium'),
        url('../fonts/AvenirNext-Medium.woff2') format('woff2'),
        url('../fonts/AvenirNext-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Superclarendon';
    src: local('Superclarendon Bold'), local('Superclarendon-Bold'),
        url('../fonts/Superclarendon-Bold.woff2') format('woff2'),
        url('../fonts/Superclarendon-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Superclarendon';
    src: local('Superclarendon Regular'), local('Superclarendon-Regular'),
        url('../fonts/Superclarendon-Regular.woff2') format('woff2'),
        url('../fonts/Superclarendon-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brandon Grotesque';
    src: local('BrandonGrotesque-Bold'),
        url('../fonts/BrandonGrotesque-Bold.woff2') format('woff2'),
        url('../fonts/BrandonGrotesque-Bold.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "Effra-font";
    src: url("../fonts/NYLICEffra_Std_Rg.ttf") format("truetype");
}
@font-face {
    font-family: "Effra-font-bold";
    src: url("../fonts/NYLICEffra_Std_Rg-b.ttf") format("truetype");
}

:root {
    --effra-font: 'Effra-font';
    --effra-font-bold: 'Effra-font-bold';
  }
  