@import "../../assets/stylesheets/core";

.user-info-wrapper {
  p {
    letter-spacing: -0.056rem;
  }

  .preview-final-img {
    height: 17rem;
  }
}
.homeSubHeading{
  font-family: var(--effra-font) !important;
  font-weight: 300;
  font-size: 22px;
  opacity: 0.9;
}
.userInfoDiv{
  max-width: 420px;
  margin: auto;
}
.user-info-section {
  width: 100%;
  min-width: 420px;

  @include media-breakpoint-up(md) {
    width: 420px;
  }
}
.placeholder-white::placeholder {
  font-family: var(--effra-font-bold) !important;
  font-size: 20px;
  font-weight: 600;
  color: #0A2243;
}

.custom-link {
  color: inherit;
  text-decoration: underline;
}

Col {
  padding: 0 !important;
}

.padding {
  padding-top: 30px;
}

.no-wrapper {
  white-space: pre;
  overflow: hidden;
}

.initials-tag {
  display: inline-flex;
  border-radius: 1.25rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
  margin: 0px 6px;
  border: solid 1.5px white;
}
.PhoneInputInput {
  background-color: transparent;
  text-transform: none;
  border: none;
  color: black;
  font-weight: 600;
  outline: none;
  border-color: none
}
input,.form-control,.dropdown-toggle{
  font-family: var(--effra-font-bold) !important;
  font-size: 20px;
  font-weight: 600;
  color: #0A2243;
}
.PhoneInputInput::placeholder{
  font-family: var(--effra-font-bold) !important;
  font-weight: 600;
  font-size: 20px;
  color: #0A2243;
}

.dropdown-toggle {
  width: 100%;
  background-color: white;
  padding: 0.8rem;
  text-align: start;
  border-radius: 28px !important;
}

.form-check-input{
  width: 25px !important;
  height: 25px !important;
  margin-right: 0.5rem;
  background-color: white;
  font-size: 22px;
}
.form-check-input:checked{
  background-color: #007BC3 !important;
}

.form-check-input:valid{
  border-color: white !important ;
}
#anchorTag{
  text-decoration: underline;
  color: white;
  cursor: pointer;
  font-size: 20px !important;
  font-family: var(--effra-font) !important;
  font-weight: 300 !important;
  opacity: .9;
}
.anchorTags{
  font-family: var(--effra-font-bold) !important;
  text-decoration: underline;
  color: white;
}
.termsModel{
  height: 75vh;
  overflow-y: scroll;
}
.modelButton{
  float:right;
  margin: 10px 20px 10px 0px;
  color: white !important;
  width: 15vh !important;
}
.termsHeading{
  text-align: center;
  font-weight: 600;
}
.termsPoints{
  font-size: 0.8rem;
}
.my-4,.my-4:hover{
  margin: 0 !important;
  width: 97%;
  height: 64px;
  background-color: #007BC3;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 28px;
  font-size: 25px;
  margin-top: 35px !important;

  &.clicked {
    background-color:#007BC3 !important; 
  }
}
.dropdown-toggle,.dropDownItem,.dropDownItem:hover{
  width: 97%;
  height: 64px;
  background-color: white !important;
  color: black;
  border-radius: 28px !important;
}
.dropDownItem,.dropDownItem:hover{
  height: 35px !important;
}
.dropdown-toggle:hover{
  background-color: white !important;
}
.footor{
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 60px 10px;
}
.header{
  width: 100%;
  padding-bottom: 20px;
}

.mt-4{
  font-size: 20px;
  font-weight: bold;
  padding: 0 13px;
  font-family: var(--effra-font-bold) !important;
}
.required{
  padding-left: 20px;
  font-family: var(--effra-font) !important;
}

.CheckBoxlabel{
  font-size: 21px;
  font-family: var(--effra-font-bold) !important;
  padding-left: 16px;
}
.p-0{
  font-size: 20px;
  font-weight: bold;
  font-family: var(--effra-font-bold) !important;
}
.px-4{
  padding:0 1.6rem !important;
}
.bottomContent{
  padding:0 1.8rem !important;
}
.form-control,.dropDownItem{
  border-radius: 28px !important;
}
.Divv{
  height: 25px;
}
.CheckBoxWithLabel{
  margin-left:3px;
}