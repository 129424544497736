html,
body {
  min-height: 100vh;
}

body {
  @extend .scroll-y;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  @extend .scroll-y;
}

// Input
.form-control {
  &:focus {
    box-shadow: none;
  }
}

.btn {
  &:focus-visible {
    box-shadow: none;
  }
}

.btn-outline-primary{
  // @include button-variant(background-color, border-color, text-color);
  @include button-outline-variant(
    $primary,
    $secondary,
    $primary,
    $primary,
    $secondary
  );
}

.btn-primary{
  // @include button-variant(background-color, border-color, text-color);
  @include button-variant(
    $primary,
    $primary,
    $secondary,
    darken($primary, 5%),
    darken($primary, 7.5%),
    $secondary,
    darken($primary, 5%),
    darken($primary, 7.5%),
    $secondary
  );
}

.btn-outline-info{
  // @include button-variant(background-color, border-color, text-color);
  @include button-outline-variant(
    $info,
    $white,
    $info,
    $info,
    $secondary
  );
}

.btn-info{
  // @include button-variant(background-color, border-color, text-color);
  @include button-variant(
    $info,
    $info,
    $white,
    $blue-pale-cornflower,
    $blue-pale-cornflower,
    $secondary,
    $blue-pale-cornflower,
    $blue-pale-cornflower,
    $secondary,
  );
}

.sg-content-section {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 420px;
  }
}

.sg-card {
  border-radius: 1.25rem;

  .sg-card-title {
    font-size: 2.375rem;
    letter-spacing: -0.048rem;
  }

  p {
    letter-spacing: -0.056rem;
  }

  span {
    line-height: 1;
  }
}

.event-tile {
  width: 5.5rem;
  height: 5.5rem;

  span {
    letter-spacing: -0.031rem;
  }

  p {
    font-size: 1.375rem;
    letter-spacing: -0.069rem;
  }
}

.sg-bg-filter{
  height: 5.5rem;
  width: 5.5rem;
  border: transparent;

  &.active {
    border: 0.125rem solid $primary;
  }

  >img {
    height: 100%;
  }
}

.sg-edit-action-section  {
  width: 100%;
  height: 12.5rem;
  max-height: 12.5rem;
}

.name-input{
  border-radius: 7px;
}

.photo-wrap {

  .confirm-screen{
    object-fit: fill;
  }
}
//Modal
.sg-modal {

  z-index: 999999;

  .modal-content {
    width: 22.5rem;
    max-width: 22.5rem;
    background-color: $white;
    border: 0;
    margin: 0 auto;
    border-radius: 1.25rem;
  }

  .modal-header {
    .btn-close {
      opacity: unset;
      background: url("../images/close.png") center/1em auto no-repeat;
    }
  }
}

/* CSS for the video container */
.video-container {
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio (adjust as needed for your desired aspect ratio) */
  position: relative;
}

/* CSS for the video element */
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Center the Element
.ele-center{
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.preview-loader{
position: fixed;
top:10%;
transform:translateX(-50%)
}

//Back button
.back-fix-btn{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color:#0D2B53;
}