@import "../../assets/stylesheets/core";

.sg-content-section {
  &.welcome-screen-section {
    // padding: 6rem 3rem 3rem;
    padding: 0.5rem 3rem;

    .get-started-btn {
      padding: 0.5rem 3.75rem;
    }
  }
}

.prudential-logo-wrap {
  margin-bottom: 5.5rem;
}

.box-wrapper {
  margin-bottom: 2rem;
  padding: 0 1.5rem;

  .box-heading {
    // font-size: 2.375rem;
    letter-spacing: -0.76px;
    line-height: 40px;
  }

  .box-subheading {
    letter-spacing: -0.063rem;
  }
}

.event-details-box {
  margin-bottom: 2rem;

  h5 {
    letter-spacing: -0.063rem;
    line-height: 1.625rem;
  }

  p {
    letter-spacing: -0.038rem;
    line-height: 1.625rem;
  }
}
